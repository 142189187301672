
import CloudFun, { defineComponent, ref } from '@cloudfun/core'
import Grid, { GridOptions } from '@/cloudfun/components/Grid.vue'
import { VxeFormProps } from 'vxe-table'
import formatDate from 'xe-utils/toDateString'

export default defineComponent({
  components: {
    Grid
  },
  setup () {
    const model = CloudFun.current?.model
    const grid = ref<any>({})
    var printColumns = [
      { field: 'Name' },
      { field: 'Description' }
    ]

    const gridOptions: GridOptions = {
      title: '操作紀錄',
      canCreate: false,
      canUpdate: false,
      canDelete: false,
      multiselect: false,
      toolbarConfig: {
        custom: true,
        refresh: true
      },
      printConfig: { sheetName: '操作紀錄', columns: printColumns, modes: ['current', 'selected', 'all'] },
      exportConfig: { filename: '操作紀錄', type: 'csv', types: ['html', 'csv'], mode: 'all', modes: ['current', 'selected', 'all'], columns: printColumns },
      columns: [
        { field: 'CreatedTime', title: '時間', showHeaderOverflow: true, showOverflow: true, sortable: true, formatter: ({ cellValue }) => formatDate(new Date(cellValue), 'yyyy/MM/dd HH:mm:ss') },
        { field: 'UserName', title: '用戶', showHeaderOverflow: true, showOverflow: true, sortable: true },
        { field: 'ActionName', title: '執行操作', showHeaderOverflow: true, showOverflow: true, sortable: true },
        { field: 'EntityTypeName', title: '異動類型', showHeaderOverflow: true, showOverflow: true, resizable: false },
        { field: 'EntityName', title: '資料名稱', showHeaderOverflow: true, showOverflow: true, resizable: false }
      ],
      promises: {
        query: model ? (params) => {
          if (!params.sortings || params.sortings.length === 0) params.sortings = [{ column: "CreatedTime", order: 1 }];
          return model.dispatch('actionLog/query', params);
        } : undefined,
        queryAll: model ? () => model.dispatch('actionLog/query') : undefined,
        save: model ? (params) => model.dispatch('actionLog/save', params) : undefined
      },
    }

    const formOptions: VxeFormProps = {
      titleWidth: 80,
      titleAlign: 'right',
      items: [
        { field: 'Time', title: '時間', span: 12, itemRender: { name: '$input', props: { type: 'datetime', readonly: true } } },
        { field: 'UserId', title: '人員', span: 12, itemRender: { name: '$input', props: { readonly: true } } },
        { field: 'ActionName', title: '執行操作', span: 12, itemRender: { name: '$input', props: { readonly: true } } },
        { field: 'EntityTypeName', title: '異動類型', span: 12, itemRender: { name: '$input', props: { readonly: true } } },
        { field: 'EntityId', title: '資料ID', span: 12, itemRender: { name: '$input', props: { readonly: true } } },
        { field: 'EntityName', title: '資料名稱', span: 12, itemRender: { name: '$input', props: { readonly: true } } },
        { span: 24, slots: { default: 'xml' } }
      ]
    }

    return {
      grid,
      gridOptions,
      formOptions
    }
  },
  methods: {
    onGridRemoveSelectedRows (rows: any, callback: any) {
      cash('#batch-dropdown').dropdown('hide')
      callback()
    },
    async onGridEdit (row: any, callback: any) {
      const entity = row.Id ? await this.$model.dispatch('actionLog/find', row.Id) : undefined
      if (entity) {
        entity.Xml.Time = new Date(entity.Xml.Time)
        Object.assign(row, entity)
      }
      callback()
    }
  }
})
